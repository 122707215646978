import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectFormFieldComponent } from './select-form-field.component';

@NgModule({
  declarations: [
    SelectFormFieldComponent,
  ],
  imports: [
    TranslateModule,
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
  ],
  exports: [SelectFormFieldComponent],
  bootstrap: [SelectFormFieldComponent],
})
export class SelectFormFieldModule { }
