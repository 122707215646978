<div class="page-header">
  <h1>Diárias</h1>
</div>

<div *ngIf="!loading">
  <div class="filters-row">
    <div class="filters-column">
      <a (click)="openDialogFiltro()" title="Abra o filtro">
        <span class="material-symbols-outlined"> filter_alt </span> Filtro
        <span *ngIf="customFilter != null"
          >&nbsp;({{ quantityKeysCustomFilter }})</span
        >
        <span class="material-symbols-outlined"> expand_more </span>
      </a>
    </div>

    <div class="filters-column">
      <a (click)="openDialogListFiltros()" title="Abra os filtros">
        <span class="material-symbols-outlined"> star </span> Meus filtros
        <span *ngIf="myFilter && customFilter == null"
          >&nbsp;({{ myFilter.nomeFiltro }})</span
        >
        <span class="material-symbols-outlined"> expand_more </span>
      </a>
    </div>

    <div class="filters-column filters-search">
      <input
        type="text"
        [(ngModel)]="termoPesquisa"
        placeholder="Pesquisar..."
        (keyup)="filtrarDados()"
      />
      <span class="material-symbols-outlined"> search </span>
    </div>

    <div class="filters-date">
      <input
        readonly
        placeholder="Data"
        (click)="dateRangePicker.open()"
        [value]="dateFilterText"
      />
      <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
      <mat-date-range-input [rangePicker]="dateRangePicker">
        <input
          matStartDate
          [formControl]="dateStartFilter"
          (dateChange)="filterDiariasByDate()"
        />
        <input
          matEndDate
          [formControl]="dateEndFilter"
          (dateChange)="filterDiariasByDate()"
        />
      </mat-date-range-input>
      <mat-icon *ngIf="dateFilterText" (click)="clearDate()">close</mat-icon>
      <mat-datepicker-toggle
        matSuffix
        [for]="dateRangePicker"
      ></mat-datepicker-toggle>
    </div>
  </div>

  <div class="status-summary-row">
    <div class="status-summary-column status-summary-pending">
      <div class="summary-status-number">
        <mat-icon class="material-symbols-outlined">
          free_cancellation
        </mat-icon>
        <span>{{ getQuantidadePendentes() }}</span>
      </div>
      <div class="summary-status-text">Diárias pendentes</div>
    </div>

    <div class="status-summary-column status-summary-waiting-approval">
      <div class="summary-status-number">
        <mat-icon class="material-symbols-outlined">event_note</mat-icon>
        <span>{{ getQuantidadeEmAprovacao() }}</span>
      </div>
      <div class="summary-status-text">Diárias em aprovação</div>
    </div>

    <div class="status-summary-column status-summary-analyzed">
      <div class="summary-status-number">
        <mat-icon class="material-symbols-outlined">event_note</mat-icon>
        <span>{{ getQuantidadeAnalisadas() }}</span>
      </div>
      <div class="summary-status-text">Diárias analisadas</div>
    </div>

    <div class="status-summary-column status-summary-finished">
      <div class="summary-status-number">
        <mat-icon class="material-symbols-outlined">event_note</mat-icon>
        <span>{{ getQuantidadeFinalizadas() }}</span>
      </div>
      <div class="summary-status-text">Diárias finalizadas</div>
    </div>
    <div
      *ngIf="logistic !== 'primaria'"
      class="status-summary-column status-summary-within-delivery-planning"
    >
      <div class="summary-status-number">
        <mat-icon class="material-symbols-outlined">event_note</mat-icon>
        <span>{{ getQuantidadeDentroDoPlanejamentoDeEntrega() }}</span>
      </div>
      <div class="summary-status-text">Dentro do planejamento de entrega</div>
    </div>

    <div class="status-summary-buttons-column">
      <button
        *ngIf="diariasSelectedForApproval.value.length > 0"
        class="batch-approval-button"
        (click)="openBatchApprovalRequestFormDialog()"
      >
        <span class="material-symbols-outlined"> article </span>
        Análise para envio ({{ diariasSelectedForApproval.value.length }})
      </button>

      <button
        class="manual-request-button"
        (click)="redirectToManualRequest()"
        title="Clique para Sinalizar"
      >
        <span class="material-symbols-outlined"> edit_calendar </span>
        Sinalizar diária manualmente
      </button>
    </div>
  </div>

  <section class="diarias-list-section">
    <div class="diaria-card-heading-wrapper">
      <div class="diaria-card diaria-card-heading">
        <div class="diaria-card-cell diaria-card-checkbox-cell">
          <mat-checkbox
            *ngIf="shouldDisplaySelectAllDiarias"
            color="primary"
            (change)="handleSelectAllDiarias()"
            [formControl]="selectAllDiarias"
          ></mat-checkbox>
        </div>

        <div *ngFor="let titulo of listHeaders" class="diaria-card-cell">
          {{ titulo }}
        </div>
      </div>
    </div>

    <ng-container *ngFor="let dado of listaDados">
      <a [routerLink]="['detalhes', dado.id]">
        <div class="diaria-card" [attr.data-status]="dado.status.toLowerCase()">
          <div class="diaria-card-cell diaria-card-checkbox-cell">
            <mat-checkbox
              *ngIf="dado.status.toLowerCase() === 'pendente'"
              color="primary"
              [value]="dado.id"
              [checked]="diariasSelectedForApproval.value.includes(dado.id)"
              (click)="$event.stopPropagation()"
              (change)="handleSelectDiaria(dado.id)"
            ></mat-checkbox>
          </div>
          <div class="diaria-card-cell">
            {{ dado.dataSolicitacao | date : "dd/MM/yyyy" }}
          </div>
          <div class="diaria-card-cell">
            <span
              class="material-symbols-outlined"
              matTooltip="Abrir o chat da viagem"
              matTooltipPosition="above"
              (click)="chatRedirect($event, dado.numeroViagem, dado.placa)"
            >
              chat_bubble
            </span>
          </div>
          <div class="diaria-card-cell">{{ dado.placa }}</div>
          <div class="diaria-card-cell diaria-card-farol">
            <mat-icon
              [attr.data-cor]="dado.statusColor.toLowerCase()"
              matTooltipPosition="above"
              [matTooltip]="tooltipsByColor[dado.statusColor]"
              class="farol-icon"
            >
              circle
            </mat-icon>
            <span
              matTooltipPosition="above"
              [matTooltip]="dado.numeroViagem.split(',').join(', ')"
              class="diaria-card-travel-number"
            >
              {{ dado.numeroViagem }}
            </span>
          </div>
          <div class="diaria-card-cell">{{ dado.tempoEmDiaria }}</div>
          <div class="diaria-card-cell">{{ dado.pesoEntrega }} KG</div>
          <div
            class="diaria-card-cell diaria-card-cell-vertical diaria-card-client"
          >
            <span class="diaria-card-client-name">{{ dado.cliente.nome }}</span>
            {{ dado.cliente.codigo }}
          </div>
          <div
            class="diaria-card-cell diaria-card-cell-vertical diaria-card-deliveries"
          >
            <div class="deliveries-progress-bar">
              <div
                class="deliveries-progress"
                [style.width.%]="calcularProgresso(dado)"
              ></div>
            </div>
            <span class="deliveries-number">
              {{ dado.numeroEntrega }}
            </span>
          </div>
          <div class="diaria-card-cell diaria-card-bold">{{ dado.motivo }}</div>
          <div class="diaria-card-cell diaria-card-bold diaria-card-status">
            {{ dado.status }}
          </div>
        </div>
      </a>
    </ng-container>
  </section>
</div>

<ng-template #batchApprovalRequestFormDialog>
  <form
    [formGroup]="batchApprovalFormGroup"
    (ngSubmit)="submitBatchApprovalRequestForm()"
    class="batch-approval-request-form"
  >
    <h2 class="batch-approval-request-form-title">
      Envio para aprovação em massa ({{
        diariasSelectedForApproval.value.length
      }})
    </h2>

    <app-approval-request-form
      [formGroup]="batchApprovalFormGroup"
      [responsibleClients]="[]"
    >
    </app-approval-request-form>

    <div class="batch-approval-request-form-buttons">
      <button mat-dialog-close class="cancel mat-flat-button">Cancelar</button>
      <button
        type="submit"
        class="submit mat-raised-button"
        [disabled]="batchApprovalFormGroup.invalid"
      >
        Enviar para aprovação
      </button>
    </div>
  </form>
</ng-template>
