import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { heightFadeAnimation } from "src/app/core/animations/height-fade/height-fade.animation";

export interface TOption {
  key: string | number;
  value: string | number;
  show?: string;
  display?: string;
  checked: boolean;
}

export enum PLACEMENT {
  TOP = "top",
  BOTTOM = "bottom",
}

@Component({
  selector: "app-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectComponent),
    },
  ],
  animations: [heightFadeAnimation],
})
export class SelectComponent implements ControlValueAccessor, OnChanges {
  @Input() id = "select-id";

  @Input() placeholder = "Texto";

  @Input() selectedText = "selecionados";

  @Input() placeholderSearchOptions = "Pesquisar";

  @Input() placeholderTotalSelected = "selecionado(s)";

  @Input() expand = false;

  @Input() options: TOption[] = [];

  public optionsOriginal: TOption[] = [];

  @Input() isDisabled = false;

  @Input() multipleSelect = true;

  @Input() invalid = false;

  @Input() externalSearch = false;

  @Output() onSelected: EventEmitter<TOption[]> = new EventEmitter<TOption[]>();

  @Output() onChangeTextSearch: EventEmitter<string> =
    new EventEmitter<string>();

  public selected: TOption[] = [];

  public selectedFiltered = [];

  public inputSearchOptions = null;

  public placement: PLACEMENT = PLACEMENT.BOTTOM;

  private onTouched = () => {};

  private onChanged = (_: TOption[]) => {};

  constructor(private cdref: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.optionsOriginal = this.options;
  }

  selectOption(): void {
    this.onTouched();
    this.onChanged(this.selected);
    this.onSelected.emit(this.selected);
  }

  handleSearch(newValue: string) {
    if (!this.externalSearch) {
      if (newValue.length > 0) {
        this.options = this.optionsOriginal.filter((option) => {
          return option.display
            .toUpperCase()
            .includes(this.inputSearchOptions.toUpperCase());
        });
      } else {
        this.options = this.optionsOriginal;
      }
    } else {
      this.onChangeTextSearch.emit(newValue);
    }
  }

  writeValue(options: TOption[]): void {
    if (!options && this.selected.length > 0) {
      this.selected = [];
      this.selectedFiltered = [];
      this.expand = false;
      this.inputSearchOptions = "";
      this.options = this.options.map((option) => {
        option.checked = false;
        return option;
      });
      this.cdref.detectChanges();
    } else if (!!options) {
      this.selected = options.filter((option) =>
        typeof option === "object" && option !== null ? option.checked : true
      );
      const checkedOptionsValues =
        this.selected.map((option) => option.value ?? option) || [];
      if (this.options?.length) {
        this.options.forEach(
          (option) =>
            (option.checked = checkedOptionsValues.includes(option.value))
        );
        this.selected = this.options.filter((option) => option.checked);
      }
      this.cdref.detectChanges();
    }
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  handleExpand(expand: boolean): void {
    this.expand = expand;
    this.cdref.detectChanges();
  }

  markAll(): void {
    this.inputSearchOptions = null;

    this.selectedFiltered = [];

    this.options.map((option) => {
      option.checked = this.selected.length !== this.options.length;
      return option;
    });

    this.selected =
      this.selected.length !== this.options.length ? this.options : [];

    this.selectOption();

    this.cdref.detectChanges();
  }

  markOnlyOneOption(option: TOption): void {
    this.options.map((select) => {
      if (option.key !== select.key) select.checked = false;
    });
    this.selected = [];
    option.checked = !option.checked;
    const optionIndex = this.options.findIndex(
      (select) => select.key === option.key
    );
    this.options[optionIndex].checked = option.checked;
    option.checked ? this.selected.push(option) : (this.selected = []);
    this.selectOption();
    this.cdref.detectChanges();
    this.toggle();
  }

  markOption(option: TOption): void {
    option.checked = !option.checked;

    const optionIndex = this.options.findIndex(
      (select) => select.key === option.key
    );

    this.options[optionIndex].checked = option.checked;

    if (this.selected.length === 0) {
      this.selected.push(option);
    } else {
      const selectedIndex = this.selected.findIndex(
        (select) => select.key === option.key
      );

      if (selectedIndex !== -1) {
        this.selected = this.selected.filter(
          (select) => select.key !== option.key
        );
      } else {
        this.selected.push(option);
      }
    }

    this.selectOption();

    this.cdref.detectChanges();
  }

  handleFilter(): void {
    if (this.inputSearchOptions.length > 0) {
      const textFinded = this.inputSearchOptions.toLowerCase().trim();

      this.selectedFiltered = this.options.filter((option) =>
        option.value.toString().toLowerCase().trim().includes(textFinded)
      );

      this.cdref.detectChanges();
    } else {
      this.selectedFiltered = this.options;
    }
  }

  handleClearInputSearchOptions(): void {
    this.inputSearchOptions = null;
    this.selectedFiltered = [];
    this.options = this.optionsOriginal;
    this.cdref.detectChanges();
  }

  toggle = () => {
    if (this.isDisabled) {
      return;
    }
    const d = document.getElementById(this.id);
    if (d?.offsetTop > screen.height * 0.55 && this.id !== "select-id") {
      this.placement = PLACEMENT.TOP;
    }
    this.expand = !this.expand;
    this.cdref?.detectChanges();
  };
}
